.range-slider {
  width: 80%;
  margin: 0 auto;
  display: flex;
}

input[type="range"] {
  width: 100%;
}

.slider-values {
  margin-top: 20px;
  font-size: 24px;
}