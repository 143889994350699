@tailwind base;
@tailwind components;
@tailwind utilities;


table {
  width: 100%;
}

table tr,td, th {
  @apply border-2 text-center p-1
}

table th{
  @apply p-3
}

/* table tr,td, th {
  @apply border-2 text-center p-3
} */

table tbody tr:nth-child(odd){
  @apply bg-slate-100
} 